<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
	<div id="app">
		<router-view></router-view>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
      <div v-if="this.userInfo !== null" class="floating-row">
        <div class="floating-whatsapp">
          <a :href="wpUrl" class="whatsapp-icon" target="_blank">
            <i class="fa fa-whatsapp"></i>
          </a>
        </div>
        <div class="floating-visible">
          <div class="floating-info">
            <span class="floating-message">¡Habla con ventas!</span>
          </div>
        </div>
      </div>
	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import { mapGetters } from 'vuex';
import api from './mixins/api.js'

export default {
  data() {
    return {
      wpUrl: '',
    };
  },
  mixins: [api],
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
    async created() {
    try {
      this.getWhatsappUrl()
      await this.$auth.renewTokens();
    } catch (e) {
      //console.log(e);
    }
  },
  methods: {

    async getWhatsappUrl() {
      const wpUrl = await this.requestGetWithoutContent(`${this.url()}api/v1/wp_url`, {})
      this.wpUrl = wpUrl.data.data
    },

    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    },
    handleWindowResize(event) {
      this.$store.dispatch('updateWindowWidth', event.currentTarget.innerWidth);
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
    this.$store.dispatch('updateWindowWidth', window.innerWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
}
// inactive for debug
// var Tawk_API=Tawk_API||{};
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/5bd22e89476c2f239ff60683/default';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
</script>

<style scoped>

.floating-row {
  display: flex;
  justify-content: space-around;
	position: fixed;
	height: 45px;
	bottom: 15px;
  left: 20px;
  z-index:401;
}

.floating-whatsapp {
  display: flex;
  justify-content: center;
  width: 45px;
	background-color:#25d366;
	border-radius: 50px;
	/* box-shadow: 2px 2px 3px #999; */
  cursor: pointer;
}

.whatsapp-icon {
  display: flex;
  align-self: center;
  color:#FFF;
  font-size: 25px;
}

.floating-info {
  display: flex;
  align-self: center;
  background-color: #FFC057;
  color: #3C1A4E;
	border-radius:10px;
  font-size:12px;
  font-weight: 700;
	width: 100%;
  margin: 0px 5px;
  cursor: pointer;
}

.floating-message {
  display: flex;
  align-self: center;
  padding: 10px;
}

.floating-visible {
  display: none;
  align-self: center;
}

.floating-whatsapp:hover + .floating-visible{
  display : block;
  left: 0;
  transition: 1s;
}
</style>
