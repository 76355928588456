/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    navbarSearchAndPinList: navbarSearchAndPinList,
    AppActiveUser: {
        id: 0,
        name: 'John Doe',
        about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
        img: 'avatar-s-11.png',
        status: 'online',
    },

    themePrimaryColor: colors.primary,

    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    userRole: null,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar component
    windowWidth: null,
    projects:[],
    countSessionInit: 0,
    userInfo: null,
    fastTrackingProjects: {},
    fastTrackingProjectsCount: 0,
    currentProjectNotification: null,
    vm0: null,
    vm1: null,
    vm2: null,
    vm3: null,
    request_process: null,
    information_completed: null,
    request_validations: null,
    verifications_response: null,
    project_finished: null,
    eventTypes: [
      { id: 0, name: 'request_process'},
      { id: 1, name: 'information_completed'},
      { id: 2, name: 'request_validations'},
      { id: 3, name: 'verifications_response'},
      { id: 4, name: 'project_finished'}
    ],
    currentProjectPP: null
}

export default state
