/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),

  projects: state => {
    return state.projects
  },
  userInfo: state => {
    return state.userInfo
  },
  currentProjectNotification: state => {
    return state.currentProjectNotification
  },
  fastTrackingProjectsCount: state => {
    return state.fastTrackingProjectsCount
  },
  fastTracking: state => {
    return state.fastTrackingProjects
  },
  vm0: state => {
    return state.vm0
  },
  vm1: state => {
    return state.vm0
  },
  vm2: state => {
    return state.vm2
  },
  vm3: state => {
    return state.vm3
  },    
  request_process: state => {
    return state.request_process
  },    
  information_completed: state => {
    return state.information_completed
  },    
  request_validations: state => {
    return state.request_validations
  },
  eventTypes: state => {
    return state.eventTypes
  },                  
  verifications_response: state => {
    return state.verifications_response
  },    
  project_finished: state => {
    return state.project_finished
  },              
  currentProject: state => {
    return state.currentProject
  },                
}

export default getters