import axios from 'axios'
import jtw from 'jsonwebtoken'
export default {
  created: function () {
  },
  methods: {
    url(){
      return process.env.VUE_APP_ROOT_API
    },
    urlPdfGenerator(){
      return process.env.VUE_APP_ROOT_REPORT
    },
    headers(){
      const tokenDecrypt = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm0, this.url()).toString(this.CryptoJS.enc.Utf8)
      const clientDecrypt = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm1, this.url()).toString(this.CryptoJS.enc.Utf8)
      const uidDecrypt = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8)
      
      let headers = {
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy-Report-Only': "default-src 'self'; script-src 'self' 'unsafe-inline'; object-src 'none'; style-src 'self' 'unsafe-inline'; img-src 'self'; media-src 'none'; frame-src 'none'; font-src 'self'; connect-src 'self'; report-uri REDACTED",
        "X-Content-Security-Policy-Report-Only": "default-src 'self'; script-src 'self' 'unsafe-inline'; object-src 'none'; style-src 'self' 'unsafe-inline'; img-src 'self'; media-src 'none'; frame-src 'none'; font-src 'self'; connect-src 'self'; report-uri REDACTED",
        'client': clientDecrypt,
        'access-token': tokenDecrypt,
        'uid': uidDecrypt,
        'token':tokenDecrypt
      }
      return headers
    },
    requestPostNoAuth(url, data){
      return this.$http.post(url, data, {'Content-Security-Policy-Report-Only': "default-src 'self'; script-src 'self' 'unsafe-inline'; object-src 'none'; style-src 'self' 'unsafe-inline'; img-src 'self'; media-src 'none'; frame-src 'none'; font-src 'self'; connect-src 'self'; report-uri REDACTED", "X-Content-Security-Policy-Report-Only": "default-src 'self'; script-src 'self' 'unsafe-inline'; object-src 'none'; style-src 'self' 'unsafe-inline'; img-src 'self'; media-src 'none'; frame-src 'none'; font-src 'self'; connect-src 'self'; report-uri REDACTED", 'X-Frame-Options': 'DENY', 'Content-Type': 'application/json' })
    },
    async requestPost(path, data){
      let res = await axios({
        method:'POST',
        data:data,
        url:path,
        headers: this.headers()
      })
      return res      
    },    
    requestGet(url, data, headers){
      return this.$http.get(url, data, headers)
    },
    async requestPut(path, data){
      let res = await axios({
        method:'PUT',
        data:data,
        url:path,
        headers: this.headers()
      })
      return res      
    },
    async requestPutWithoutHeaders(path, data){
      let res = await axios({
        method:'PUT',
        data:data,
        url:path,
        headers: {}
      })
      return res      
    },    
    async requestGetWithoutContent(path,data){
      let res = await axios({
        method:'GET',
        data:data,
        url:path,
        headers: this.headers()
      })
      return res
    },
    async requestPutWithoutContent(path,data){
      let res = await axios({
        method:'PUT',
        data:data,
        url:path,
        headers: this.headers()
      })
      return res
    },    
    linkReport(projectId,userId,fileName){
      this.progressReport = true
      const jwt = require('jsonwebtoken')
      const token = jwt.sign({
        secrect_key:process.env.VUE_APP_SECRET_KEY,
        userId:userId
      },process.env.VUE_APP_JWT_SECRET_KEY)
      this.downloadBase64File(this.urlPdfGenerator()+'reports/'+projectId,token,fileName)
   
    },
    linkResumen(projectId,userId,fileName){
      this.progressReport = true
      const jwt = require('jsonwebtoken')
      const token = jwt.sign({
        secrect_key:process.env.VUE_APP_SECRET_KEY,
        userId:userId
      },process.env.VUE_APP_JWT_SECRET_KEY)
      this.downloadBase64File(this.urlPdfGenerator()+'reports/resumen/'+projectId,token,fileName)
    },
    async generateToken() {
      let uid = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8)      
      let password = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm3, this.url()).toString(this.CryptoJS.enc.Utf8)
      let domainIntranet = this.urlPdfGenerator()
      let pathLogin = 'api/v1/login'
      let response = await this.$http.post(domainIntranet+pathLogin, { email: uid, password: password}, { headers: { 'Content-Type': 'application/json' }})
      let token = response.data.data.token
      return token
    },

    downloadBase64File(url,token,fileName, extension=null) {
      extension == null ? extension = '.pdf' : extension = extension
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        headers:{
          token:token
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}${extension}`);
        document.body.appendChild(link);
        link.click();
        this.progressReport = false
        this.$vs.notify({
          title: 'Mensaje de Globalwork',
          text: 'Reporte generado',
          color: 'success',
          position: 'bottom-left'
        })
      });
      
    }
  }
}
