/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
//import 'firebase/auth'

Vue.use(Router)


const routes = [
    {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
        path: '',
        component: () => import('./layouts/main/Main.vue'),
        children: [
            // =============================================================================
            // Dashboard Routes
            // =============================================================================
            {
                path: '/',
                redirect: '/dashboard/analytics'
            },
            {
                path: '/dashboard/analytics',
                name: 'dashboardAnalytics',
                component: () => import('./views/DashboardAnalytics.vue'),
                meta: {
                    rule: 'editor'
                }
            },


            // =============================================================================
            // Application Routes
            // =============================================================================
            {
                path: '/users',
                name: 'users',
                component: () => import('./views/pages/users/Users.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/candidates',
                name: 'candidates',
                component: () => import('./views/pages/candidates/Candidates.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/graphics',
                name: 'graphics',
                component: () => import('./views/Graphics.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('./views/pages/notifications/Notifications.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '',
                component: () => import('./layouts/candidates/Main.vue'),
                children: [
                    {
                        path: '/candidates/fill_information',
                        name: 'fillInformation',
                        component: () => import('@/views/candidates/FillInformation.vue'),
                        meta: {
                            // pageTitle: 'Fill information',
                            rule: 'editor'
                        },
                    },
                ]
            },
            {
                path: '/projects',
                name: 'projects',
                component: () => import('./views/pages/projects/Projects.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
              path: '/project_new',
              name: 'new_project',
              component: () => import('./views/pages/project/ProjectCreation.vue'),
              meta: {
                  rule: 'editor'
              }
          },
            {
                path: '/project',
                name: 'project',
                component: () => import('./views/pages/project/Project.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/apps/todo',
                name: 'todo',
                component: () => import('./views/apps/todo/Todo.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/apps/chat',
                name: 'chat',
                component: () => import('./views/apps/chat/Chat.vue'),
                meta: {
                    rule: 'editor'
                }
            }
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
            // =============================================================================
            // PAGES
            // =============================================================================
            {
                path: '/instruction',
                name: 'instruction',
                component: () => import('./views/pages/instruction.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/callback',
                name: 'authCallback',
                component: () => import('@/views/Callback.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/login',
                name: 'pageLogin',
                component: () => import('@/views/pages/Login.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/register',
                name: 'pageRegister',
                component: () => import('@/views/pages/Register.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/forgot-password',
                name: 'pageForgotPassword',
                component: () => import('@/views/pages/ForgotPassword.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/reset-password',
                name: 'pageResetPassword',
                component: () => import('@/views/pages/ResetPassword.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/change-password',
                name: 'pageChangePassword',
                component: () => import('@/views/pages/ChangePassword.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/error-404',
                name: 'pageError404',
                component: () => import('@/views/pages/Error404.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/error-500',
                name: 'pageError500',
                component: () => import('@/views/pages/Error500.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/not-authorized',
                name: 'pageNotAuthorized',
                component: () => import('@/views/pages/NotAuthorized.vue'),
                meta: {
                    rule: 'editor'
                }
            },
            {
                path: '/pages/maintenance',
                name: 'pageMaintenance',
                component: () => import('@/views/pages/Maintenance.vue'),
                meta: {
                    rule: 'editor'
                }
            },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/pages/error-404'
    },
]



const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: routes
})



router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')

    if (appLoading) {
        appLoading.style.display = "none";
    }
})



router.beforeEach((to, from, next) => {
    let auth = localStorage.getItem('auth')

    if (to.fullPath == '/dashboard/analytics' || to.fullPath == '/project' || to.fullPath == '/projects' || to.fullPath == '/users' || to.fullPath == '/graphics' || to.fullPath == '/project_new') {
        if (auth == null) {
            next('/pages/login');
        }
    }

    if (to.fullPath == '/pages/login') {
        if (auth != null) {
            next('/dashboard/analytics');
            // se debe validar el token
        }
    }

    if (to.name == 'project') {
        localStorage.setItem('prev', 1);
    } else if (to.name != 'projects') {
        localStorage.setItem('prev', null);
    }

    next()
})



export default router
