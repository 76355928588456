var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("link", {
        attrs: {
          rel: "stylesheet",
          href: "https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css",
        },
      }),
      this.userInfo !== null
        ? _c("div", { staticClass: "floating-row" }, [
            _c("div", { staticClass: "floating-whatsapp" }, [
              _c(
                "a",
                {
                  staticClass: "whatsapp-icon",
                  attrs: { href: _vm.wpUrl, target: "_blank" },
                },
                [_c("i", { staticClass: "fa fa-whatsapp" })]
              ),
            ]),
            _vm._m(0),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "floating-visible" }, [
      _c("div", { staticClass: "floating-info" }, [
        _c("span", { staticClass: "floating-message" }, [
          _vm._v("¡Habla con ventas!"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }