var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-input-group flex" }, [
    this.$slots.prepend
      ? _c(
          "div",
          {
            staticClass: "vx-input-group-prepend flex",
            class: _vm.prependClasses,
          },
          [_vm._t("prepend")],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "vx-input-group-default flex-grow" },
      [_vm._t("default")],
      2
    ),
    this.$slots.append
      ? _c(
          "div",
          {
            staticClass: "vx-input-group-append flex",
            class: _vm.appendClasses,
          },
          [_vm._t("append")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }