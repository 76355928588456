/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

import VueFlatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatpickr);
Vue.component('vue-flatpickr', VueFlatpickr);

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

export const eventBus = new Vue()

// axios
import axios from 'axios'
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Algolia - Instant Search
import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// Firebase
//import '@/firebase/firebaseConfig'


// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuesax Admin Filters
import './filters/filters'


// Clipboard
// import VueClipboard from 'vue-clipboard2'
// Vue.use(VueClipboard);


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// Gtag
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: {
      id: 'G-L0KE3ZL0L1',
      params: {
          send_page_view: false
      }
  }
}, router)

// VeeValidate
import es from 'vee-validate/dist/locale/es'
import VeeValidate, { Validator } from "vee-validate";
Vue.use(VeeValidate);
Validator.localize("es", es);

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: 'AIzaSyCF5SfRuKX2envJL-5cM4ruaOq1-KI0wbM',
        libraries: 'places', // This is required if you use the Auto complete plug-in
    },
})

// Vuejs - Vue wrapper for hammerjs
// import { VueHammer } from 'vue2-hammer'
// Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    acl,
    render: h => h(App)
}).$mount('#app')

let oldXHROpen = window.XMLHttpRequest.prototype.open;

window.XMLHttpRequest.prototype.open = function() {
 if(this.withCredentials === true){
  this.withCredentials = false;
 }
 this.addEventListener('load', function() {
  let responseText = JSON.parse(this.responseText)
  if (responseText.errors == 'Usuario no autenticado') {
    window.localStorage.clear()
    window.location.href = '/'
  }
 });

 return oldXHROpen.apply(this, arguments);
}
